.App {
  text-align: left;
}

.simplebar-scrollbar::before {
	/* background-color: red; */
	/* max-height: 0px; */
	display: none;
}

.hideScrollbar {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.hideScrollbar::-webkit-scrollbar {
	width: 0;
	height: 0;
}