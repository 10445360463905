.playerWrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  margin-bottom: 18px;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}